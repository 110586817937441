import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export default function useGetParams() {
    const [searchParams] = useSearchParams();
    const accountId = searchParams.get('accountId');
    const billingAddress: boolean = searchParams.get('billingAddress') === 'true'
    useEffect(() => {
        console.log('params: ', { accountId, billingAddress})
    }, [])
    
    return { accountId, billingAddress }
}