import { states } from "./States"

export default function StatesSelect({formData, handleInputChange}: {formData: any, handleInputChange: any }) {
    console.log(formData)
    return (
        <div className={`mb-4`}>
            <label className="block text-sm font-medium mb-1">States</label>
            <select
                name={'state'}
                value={formData.state}
                onChange={handleInputChange}
                className="border rounded w-full py-2 px-3"
            >
                {states.map((state: any) => 
                    <option key={state.value} value={state.value}>{state.label}</option>
                )}
            </select>
        </div>
    )
}