import StatesSelect from "./StatesSelect";

interface BillingAddressFormProps {
    addressLine1: string;
    setAddressLine1: (value: string) => void;
    addressLine2: string;
    setAddressLine2: (value: string) => void;
    city: string;
    setCity: (value: string) => void;
    state: string;
    setState: (value: string) => void;
    zipcode: string;
    setZipcode: (value: string) => void;
    country: string;
    setCountry: (value: string) => void;
}

export default function BillingAddressForm({
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    state,
    setState,
    zipcode,
    setZipcode,
    country,
    setCountry
}: BillingAddressFormProps) {
    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setter: (value: string) => void
    ) => {
        setter(event.target.value);
    };

    return (
        <div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Address Line 1</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                    placeholder="Street address"
                    value={addressLine1}
                    onChange={(e) => handleInputChange(e, setAddressLine1)}
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Address Line 2</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                    placeholder="Apartment, suite, unit, etc. (optional)"
                    value={addressLine2}
                    onChange={(e) => handleInputChange(e, setAddressLine2)}
                />
            </div>

            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">City</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                    placeholder="City"
                    value={city}
                    onChange={(e) => handleInputChange(e, setCity)}
                />
            </div>

            <div className="grid grid-cols-3 gap-4 mb-4">
                <StatesSelect formData={{state}} handleInputChange={(e: any) => handleInputChange(e, setState)} />
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Zipcode</label>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                        placeholder="Zipcode"
                        value={zipcode}
                        onChange={(e) => handleInputChange(e, setZipcode)}
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Country</label>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                        placeholder="Country"
                        disabled
                        value={country}
                        onChange={(e) => handleInputChange(e, setCountry)}
                    />
                </div>
            </div>
        </div>
    );
}