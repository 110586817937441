import { useState } from "react";
import useGetParams from "../hooks/useGetParams";
import useCreateFinixToken from "../hooks/useCreateFinixToken";
import useSetMessageHandler from "../hooks/useSetMessageHandler";
import { createSynswiPaymentInstrument } from "@/utils/utils";


export default function useCardFormData() {
    const [cardNumber, setCardNumber] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');
    const [name, setName] = useState('');
    const [zipCode, setZipCode] = useState('');
    const { accountId } = useGetParams()
    const { createFinixToken } = useCreateFinixToken()

    useSetMessageHandler(submit, clearForm)

    function transformExp(expiry: string) {
        let expMonth, expYear
        let arr = expiry.split('/')
        expMonth = arr[0]
        expYear = arr[1]
        return { expMonth, expYear }
    }

    function handleChangeExpiry(value: string) {
        value = value.replace(/\D/g, '');
        if (value.length > 2) {
            value = value.slice(0, 2) + '/' + value.slice(2, 4);
        }
        setExpiry(value);
    }

    function clearForm() {
        setCardNumber('')
        setExpiry('')
        setCvv('')
        setName('')
    }

    const validateForm = () => {
        if (!cardNumber.match(/^\d{16}$/)) {
            throw new Error("Card number must be 16 digits");
        }
        if (!name) {
            throw new Error("Cardholder name is required");
        }
        if (!cvv.match(/^\d{3,4}$/)) {
            throw new Error("CVV must be 3 or 4 digits");
        }
        if (!expiry) {
            throw new Error('Expiration cannot be empty')
        }
        if (!zipCode) {
            throw new Error('Zip code is required')
        }
    };

    async function submit() {
        try {
            validateForm()
        } catch (error: any) {
            console.error('form is not valid: ', error)
            window.parent.postMessage({ message: error.message, type: 'tokenization_failure' }, '*');
            return
        }
        const { expMonth, expYear } = transformExp(expiry)
        const finixTokenRequest = {
            type: "PAYMENT_CARD",
            country: "USA",
            currency: "USD",
            name: name,
            number: cardNumber,
            expiration_month: parseInt(expMonth),
            expiration_year: parseInt(expYear) + 2000,
            security_code: cvv,
            address: {
                country: "USA",
                postal_code: zipCode
            }
        };
        try {
            const token = await createFinixToken(finixTokenRequest)
            let paymentInstrument = await createPaymentInstrument(token, expMonth, expYear)
            window.parent.postMessage({ paymentInstrumentId: paymentInstrument.id, type: 'tokenization_success' }, '*');
        } catch (err: any) {
            console.error(err)
            window.parent.postMessage({ message: err.message, type: 'tokenization_failure' }, '*');
        }
    }

    async function createPaymentInstrument(token: string, expMonth: string, expYear: string) {
        const synswiReq = {
            accountId,
            token,
            type: 'card',
            card: {
                cardNumber: cardNumber,
                expMonth: expMonth,
                expYear: expYear,
                cvv: cvv,
            },
            owner: {
                name: { fullName: name },
            }
        }
        return await createSynswiPaymentInstrument(synswiReq)
    }

    return { cardNumber, expiry, cvv, name, setCardNumber, setExpiry, setCvv, setName, accountId, createFinixToken, handleChangeExpiry, zipCode, setZipCode, submit }
}