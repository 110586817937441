import { useState } from "react";
import useBillingAddressData from "../hooks/useBillingAddressData";
import useGetParams from "../hooks/useGetParams";
import { createFinixToken, createSynswiPaymentInstrument } from "@/utils/utils";
import { validateBillingAddress } from "./validateBillingAddress";

export default function useBankformData() {
    const { accountId, billingAddress } = useGetParams()
    const [accountNumber, setAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountType, setAccountType] = useState('');
    const [name, setName] = useState('');
    const billingAddressData = useBillingAddressData()

    const validateForm = () => {
        try {
            if (!accountNumber) {
                throw new Error("Account number is required")
            }
            if (!name) {
                throw new Error("Name is required")
            }
            if (!routingNumber) {
                throw new Error("Routing number is required")
            }
            if (accountType === '') {
                throw new Error("accountType is required")
            }
            if (billingAddress) {
                validateBillingAddress(billingAddressData)
            }
        } catch (error: any) {
            let message = error.message
            console.error('form is not valid: ', message)
            window.parent.postMessage({ message: message, type: 'tokenization_failure' }, '*');
            return false
        }
        return true
    };

    function clearForm() {
        setAccountNumber('')
        setRoutingNumber('')
        setAccountType('')
        setName('')
        billingAddressData.clearForm()
    }

    async function submit() {
        if (validateForm()) {
            const finixTokenRequest = {
                type: "BANK_ACCOUNT",
                country: "USA",
                currency: "USD",
                name: name,
                bank_code: routingNumber,
                account_number: accountNumber,
                account_type: accountType.toUpperCase(),
                address: {
                    country: "USA"
                }
            };
            try {
                const token = await createFinixToken(finixTokenRequest)
                let paymentInstrument = await createPaymentInstrument(token)
                window.parent.postMessage({ paymentInstrumentId: paymentInstrument.id, type: 'tokenization_success' }, '*');
            } catch (err: any) {
                console.error(err)
                window.parent.postMessage({ message: err.message, type: 'tokenization_failure' }, '*');
            }
        }
    }

    async function createPaymentInstrument(token: string) {
        const synswiReq: any = {
            accountId: accountId,
            token,
            type: 'bank',
            bank: {
                accountNumber: accountNumber,
                routingNumber: routingNumber,
                accountType: accountType,
            },
            owner: {
                name: {
                    fullName: name
                }
            }
        }
        if (billingAddress) {
            synswiReq.billingAddress = {
                addressLine1: billingAddressData.addressLine1,
                addressLine2: billingAddressData.addressLine2,
                city: billingAddressData.city,
                state: billingAddressData.state,
                zipcode: billingAddressData.zipcode,
                country: billingAddressData.country
            }
        }
        return await createSynswiPaymentInstrument(synswiReq)
    }



    return {
        accountId, billingAddress, ...billingAddressData, accountNumber,
        setAccountNumber, routingNumber, setRoutingNumber, accountType, setAccountType, name,
        setName, clearForm, submit,
    }
}