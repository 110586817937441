import { useState } from 'react';

export default function useBillingAddressData() {
    const [addressLine1, setAddressLine1] = useState<string>('');
    const [addressLine2, setAddressLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zipcode, setZipcode] = useState<string>('');
    const [country, setCountry] = useState<string>('USA');
    function clearForm() {
        setAddressLine1('');
        setAddressLine2('');
        setCity('');
        setState('');
        setZipcode('');
        setCountry('USA');
    }
    return {
        addressLine1,
        addressLine2,
        city,
        state,
        zipcode,
        country,
        setAddressLine1,
        setAddressLine2,
        setCity,
        setState,
        setZipcode,
        setCountry,
        clearForm
    };
};
 