export function validateBillingAddress(billingAddress: any) {
    if (!billingAddress.addressLine1) {
        throw new Error('Address Line 1 is required')
    }
    if (!billingAddress.city) {
        throw new Error('city is required')

    }
    if (!billingAddress.state) {
        throw new Error('state is required')

    }
    if (!billingAddress.zipcode) {
        throw new Error('zipcode is required')

    }
    if (!billingAddress.country) {
        throw new Error('country is required')

    }
}