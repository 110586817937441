import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import CardForm from "./pages/cardform/CardForm";
import BankForm from "./pages/bankform/BankForm";

export const router = createBrowserRouter([
    {
        path: '/card-form',
        element: <CardForm />
    },
    {
        path: '/bank-form',
        element: <BankForm />
    }
]);

function Routes() {
    return (
        <RouterProvider router={router} />
    );
}

export default Routes;
