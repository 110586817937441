import useCardFormData from "./useCardFormData";

export default function CardForm() {
    const {submit, cardNumber, expiry, cvv, name, setCardNumber, setCvv, setName, accountId, handleChangeExpiry, zipCode, setZipCode } = useCardFormData()

    if (!accountId) {
        return (
            <div>accountId is not given</div>
        )
    }

    return (
        <div className="p-1">
            <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Cardholder name</label>
                <input
                    type="text"
                    id="name"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm  placeholder:italic placeholder:text-sm"
                    placeholder="Full name on card"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700 mb-1">Card information</label>
                <div className="relative">
                    <input
                        type="text"
                        id="cardNumber"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="Card number"
                        value={cardNumber}
                        onChange={(e) => setCardNumber(e.target.value)}

                    />
                    <div className="absolute right-3 top-1/2 -translate-y-1/2 flex">
                        <img src="/networks_image.jpg" className="h-4" />
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="MM / YY"
                        value={expiry}
                        onChange={(e) => handleChangeExpiry(e.target.value)}
                    />
                </div>
                <div className="pl-2">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="CVC"
                        value={cvv}
                        onChange={(e) => setCvv(e.target.value)}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                    />
                </div>
            </div>
            {/* <button onClick={submit}>test</button> */}
        </div>
    )
}