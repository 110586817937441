import { useEffect, useRef } from "react";

export default function useSetMessageHandler(submit: Function, clearForm: Function) {
    const handleMessageRef = useRef<(event: any) => void>(() => { });

    useEffect(() => {
        handleMessageRef.current = async (event: any) => {
            if (event.data.type === 'submit_form') {
                console.log(event);
                await submit();
            } else if (event.data.type === 'clear_form') {
                clearForm()
            }
        };
    }, [submit]);

    useEffect(() => {
        const stableMessageHandler = (event: any) => {
            handleMessageRef.current(event);
        };
        window.addEventListener('message', stableMessageHandler);
        const intervalId = setInterval(() => {
            sendHeightToParent()
        }, 100);
        return () => {
            window.removeEventListener('message', stableMessageHandler);
            clearInterval(intervalId);
        };
    }, []);

    function sendHeightToParent() {
        const height = document.body.scrollHeight;
        window.parent.postMessage({ type: 'set_height', height: height }, '*');
    }
}