// sandbox
export const config = {
    applicationId: 'APjgE4iSWF6vCt4xo5RMsGEM',
    finixEnvironment: 'sandbox',
    synswiBaseUrl: "https://api-sandbox.synswi.com/v1",
}

// development
// export const config = {
//     applicationId: 'APjgE4iSWF6vCt4xo5RMsGEM',
//     finixEnvironment: 'sandbox',
//     synswiBaseUrl: "https://api-dev.synswi.com/v1",
// }

// production
// export const config = {
//     applicationId: "APiPucGpXxB3RL6GmPYRPfqa", // production
//     finixEnvironment: 'live',
//     synswiBaseUrl: "https://api.synswi.com/v1"
// }