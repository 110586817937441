import SelectAccountType from "./SelectAccountType";
import useSetMessageHandler from "../hooks/useSetMessageHandler";
import useBankformData from "./useBankformData";
import BillingAddressForm from "../billing-address/BillingAddressForm";

export default function BankForm() {
    const bankformData = useBankformData()
    useSetMessageHandler(bankformData.submit, bankformData.clearForm)

    if (!bankformData.accountId) {
        return (
            <div>accountId is not given</div>
        )
    }

    return (
        <div className="p-1">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Name on account</label>
                <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none md:text-sm placeholder:italic placeholder:text-sm"
                    placeholder="Full name on the account"
                    value={bankformData.name}
                    onChange={(e) => bankformData.setName(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Account Number</label>
                <div className="relative">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="Account Number"
                        value={bankformData.accountNumber}
                        onChange={(e) => bankformData.setAccountNumber(e.target.value)}
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">Routing Number</label>
                <div className="relative">
                    <input
                        type="text"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none placeholder:italic placeholder:text-sm md:text-sm"
                        placeholder="Routing Number"
                        value={bankformData.routingNumber}
                        onChange={(e) => bankformData.setRoutingNumber(e.target.value)}
                    />
                </div>
            </div>
            <div className="mb-4">
                <SelectAccountType accountType={bankformData.accountType} setAccountType={bankformData.setAccountType} />
            </div>
            {bankformData.billingAddress &&
                <BillingAddressForm
                    addressLine1={bankformData.addressLine1}
                    setAddressLine1={bankformData.setAddressLine1}
                    addressLine2={bankformData.addressLine2}
                    setAddressLine2={bankformData.setAddressLine2}
                    city={bankformData.city}
                    setCity={bankformData.setCity}
                    state={bankformData.state}
                    setState={bankformData.setState}
                    zipcode={bankformData.zipcode}
                    setZipcode={bankformData.setZipcode}
                    country={bankformData.country}
                    setCountry={bankformData.setCountry}
                />
            }
            {/* <button onClick={bankformData.submit}>test</button> */}
            {/* {`http://localhost:3000/bank-form?accountId=acc-c068c34e-b12d-48b1-bc5f-c85edae9fc9b&ownerType=merchant`} */}
        </div>
    )
}